<template>
  <UserBioBox>
    <sdCards headless>
      <address class="user-info">
        <sdHeading
          as="h5"
          class="user-info__title"
        >
          {{ $t.myContactInformation }}
        </sdHeading>
        <ul class="user-info__contact">
          <li>
            <sdFeatherIcons
              type="mail"
              size="14"
            />
            <span>{{ user.email ?? '-' }}</span>
          </li>
          <li>
            <sdFeatherIcons
              type="phone"
              size="14"
            />
            <span>{{ user.tel ?? '-' }}</span>
          </li>
          <li>
            <sdFeatherIcons
              type="globe"
              size="14"
            />
            <span>
              {{ user.town ? user.town + '/' : '' }}{{ user.city ? user.city + '/' : '' }}{{ user.country ?? '' }}
            </span>
          </li>
        </ul>
        <div class="user-info__skills">
          <router-link :to="{name: 'O.Settings.Page'}">
            <sdButton
              type="light"
              outlined
              class="btn-outlined"
            >
              <sdFeatherIcons
                type="airplay"
                size="14"
              />&nbsp; {{ $t.updateContactInformation }}
            </sdButton>
          </router-link>
        </div>
      </address>
    </sdCards>
  </UserBioBox>
</template>
<script>
import { UserBioBox } from './style'

export default {
  name: 'UserBio',
  components: { UserBioBox },
  props: {
    user: {
      type: Object,
      default: () => {
      },
    },
  },
}

</script>
